import * as VueRouter from 'vue-router'
const routes = [
    { path: '/', name: "dashboard", component: () => import("./pages/index.vue"), meta: { transition: 'slide-left' } },
    { path: '/users', name: "user-list", component: () => import("./pages/usermanagement/index.vue"), meta: { transition: 'slide-right' } },
    { path: '/users/:id', name: "user-id", component: () => import("./pages/usermanagement/_id.vue"), meta: { transition: 'slide-right' } },
    { path: '/login', name: "login", component: () => import("./pages/login.vue"), meta: { transition: 'slide-right' } },
    { path: '/category', name: "category", component: () => import("./pages/category/index.vue"), meta: { transition: 'slide-right' } },
    { path: '/category/:type/:id', name: "category-id", component: () => import("./pages/category/_id.vue"), meta: { transition: 'slide-right' } },
    { path: '/language', name: "language", component: () => import("./pages/language/index.vue"), meta: { transition: 'slide-right' } },
    { path: '/language/:id', name: "language-id", component: () => import("./pages/language/_id.vue"), meta: { transition: 'slide-right' } },
    { path: '/:type', name: "content", component: () => import("./pages/content/index.vue"), meta: { transition: 'slide-left' } },
    { path: '/:type/:id', name: "content-id", component: () => import("./pages/content/_id.vue"), meta: { transition: 'slide-left' } },
    { path: '/:type/:id/preview', name: "content-preview", component: () => import("./pages/content/preview/_id.vue"), meta: { transition: 'slide-left' } },
];

const router = VueRouter.createRouter({
    history: VueRouter.createWebHistory(),
    routes
});

export default (store) => {
    router.beforeEach(async (to) => {
        if(!store.getters.token && to.name !== "login"){
            router.push({name: "login"})
        }else if(store.getters.token && !store.getters.isLogin){
            store.commit("toggleLogin");
        }   
    })
    return router;
};