<template>
  <div class="md:flex flex-row bg-white w-screen h-screen gap-2">
    <HeaderLayout />
    <div class="md:h-screen overflow-y-auto justify-center w-full">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import HeaderLayout from '@/components/Header.vue'
export default {
  name: "DefaultLayout",
  components: {
    HeaderLayout
  },
}
</script>
