<template>
    <div class="animate-bounce flex fixed items-center gap-1 m-5 p-2 bg-slate-600 text-white rounded drop-shadow-md bottom-0 right-1 z-50">
        <span class="loader ease-linear rounded-full border-4 border-gray-200 h-6 w-6"></span>
        <div class="font-semibold">Waiting....</div>
    </div> 
</template>

<script>
export default {
    name:"AjaxLoader"
}
</script>

<style scoped>
.loader {
	border-top-color: #fff;
	-webkit-animation: spinner 1.5s linear infinite;
	animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>