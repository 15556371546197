import { createApp } from 'vue'
import './assets/template.scss'
import store from './store'
import router from './router'
import Layout from './layouts/default.vue'
import VueMarkdown from './components/VueMarkdown.vue'
import ContentButton from './components/Button.vue';
import AjaxLoader from './components/Loader.vue';
import MessageToaster from './components/Toast.vue';
import DialogBox from './components/Dialog.vue';
import DictionaryView from './components/Dictionary.vue';
import moment from 'moment'
import App from './App.vue'
import Mixin from './library/mixins'

export const build = () => {
    const app = createApp(App)
    app.config.globalProperties.$moment = moment;
    app.component("VueMarkdown", VueMarkdown)
    app.component("ContentButton", ContentButton)
    app.component("AppLayout", Layout);
    app.component("AjaxLoader", AjaxLoader);
    app.component("MessageToaster", MessageToaster)
    app.component("DialogBox", DialogBox)
    app.component("DictionaryView", DictionaryView)
    app.mixin(Mixin);
    app.use(store);
    app.use(router(store));
    return app;
}