<template>
    <div class="inline-block">
        <div v-if="isShow" :class="dialogClass">
            <div :class="dialogContentClass">
                <button @click="toggleCard" class="absolute hover:bg-red-200 justify-center px-2 h-8 font-bold text-black text-right">&times;</button>
                <VueMarkdown :class="classes" :source="content.body" />
            </div>
        </div>
        <span @click="toggleCard" class="cursor-pointer underline decoration-dotted decoration-2 text-primary">{{ title }}</span>
    </div>
</template>
<script>
    export default {
        name: "DictionaryView",
        props: {
            id: {
                type: String
            },
            title: {
                type: String
            }
        },
        data(){
            return {
                isShow: false,
                content: {
                    body: "",
                },
                dialogContentClass: 'grid grid-flow-row bg-white w-2/3 h-96 rounded-md',
                classes: 'px-2 overflow-y-auto'
            }
        },
        created(){
            this.getOne(`content/dictionary`, this.id).then(data => {
                const _data = JSON.parse(data);
                this.content = _data;
                this.dialogContentClass += ` lang-${_data.style.find(x => x.key === "lang")?.value || ""}`;
                this.classes += ` lang-${_data.style.find(x => x.key === "lang")?.value || ""}`;
            })
        },
        computed: {
            dialogClass(){
                return this.isShow ? 'flex transition-all items-center justify-center top-0 left-0 ease-out fixed w-screen h-screen z-50 bg-slate-800/70' : 'hidden'
            },
            lang(){
                return this.content.style.find(x => x.key === "lang")?.value || "";
            }
        },
        methods: {
            toggleCard(){
                this.isShow = !this.isShow
            }
        }
    }
</script>