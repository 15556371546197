<template>
  <AppLayout>
    <AjaxLoader v-if="isWaiting" />
    <MessageToaster />
    <DialogBox />
    <router-view v-slot="{ Component, route }">
      <transition :name="'fade'"> 
          <component :is="Component" :key="route.path" />
      </transition>
    </router-view>
  </AppLayout>
</template>

<script>
export default {
  created(){
    this.getProfile()
  }
}

</script>
