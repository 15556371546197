import { createStore } from 'vuex'

const state = {
    isLogin: false,
    isMenu: true,
    isWaiting: false,
    isDialog: false,
    dialogOptions: {
        title: "Encyclopedia of Muhammad",
        message:"Are you sure ?",
        actions: [],
    },
    toast: "",
};

const getters = {
    isLogin(state){
        return state.isLogin;
    },
    isMenu(state){
        return state.isMenu;
    },
    isWaiting(state){
        return state.isWaiting
    },
    isDialog(state){
        return state.isDialog
    },
    toast(state){
        return state.toast;
    },
    token(){
        return window.localStorage.getItem("token")
    },
    profile(){
        return window.localStorage.getItem("profile")
    }
}

const mutations = {
    toggleLogin(state){
        state.isLogin = !state.isLogin;
    },
    toggleMenu(state){
        state.isMenu = !state.isMenu
    },
    toggleWaiting(state, payload){
        state.isWaiting = payload;
    },
    toggleDialog(state, payload){
        state.isDialog = payload
    },
    showToast(state, payload){
        state.toast = payload;
    },
    setDialogOptions(state, payload){
        state.dialogOptions = {...state.dialogOptions,...payload}
    },
    setToken(state, payload){
        if(payload){
            window.localStorage.setItem("token", payload);
        }else{
            window.localStorage.clear();
        }
    },
    setProfile(state, payload){
        if(payload){
            window.localStorage.setItem("profile", payload);
        }else{
            window.localStorage.clear();
        }
    }
};

export default createStore({
    devtools: false,
    state,
    mutations,
    getters
});