<template>
    <div v-if="getState().isDialog" class="flex transition-all items-center justify-center top-0 left-0 ease-out fixed w-screen h-screen z-50 bg-slate-800/70">
        <div class="grid grid-flow-row bg-white w-96 h-40 rounded-md">
            <div class="bg-primary text-white px-2 h-8 rounded-b-md font-bold text-center">{{ getState().dialogOptions.title }}</div>
            <p class="px-2">
                {{ getState().dialogOptions.message }}
            </p>
            <div class="flex gap-2 justify-end p-2">
                <button v-for="(action, key) in getState().dialogOptions.actions" :key="key" :class="action.class" @click="action.action">{{ action.title }}</button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "DialogBox"
}
</script>