<template>
  <div v-if="getState().toast" class="animate-bounce flex fixed items-center gap-1 m-5 p-2 bg-slate-600 text-white rounded drop-shadow-md bottom-0 right-1 z-50">
        <div class="font-semibold">{{getState().toast}}</div>
    </div>
</template>

<script>
export default {
    name: "MessageToaster",
    created(){
        setTimeout(() => this.$store.commit("showToast", ""), 2000);
    },
    updated(){
        setTimeout(() => this.$store.commit("showToast", ""), 2000);
    }
}
</script>

<style>

</style>