<template>
  <span v-if="$store.state.isLogin" :class="`absolute hidden`" style="z-index: 9999;">
    <button @click="toggleMenu" class="bg-slate-800 rounded-lg text-white font-bold px-2 m-2">{{ isMenu ? '&times;' : 'Menu' }}</button>
  </span>
  <div :class="toggleClass" v-if="$store.state.isLogin">
    <div class="grid bg-primary text-white gap-1 m-2 p-2 rounded-xl">
      <span class="flex font-semibold">{{ this.profile ? JSON.parse(this.profile || {})?.name || "" : "" }}</span>
      <span class="flex text-xs font-semibold">{{ this.profile ? JSON.parse(this.profile || {})?.email || "" : "" }}</span>
    </div>
    <MenuList :items="routes" />
  </div>
</template>

<script>
import MenuList from './Menu/list.vue'
export default {
  name: "MainHeader",
  components: {
    MenuList
  },
  data(){
    return {
      routes: [
        {title: "Dashboard", class: "border-b-2 border-primary hover:bg-primary cursor-pointer hover:text-white hover:font-bold p-2 mx-2", action: () => this.$router.push({path: "/"})},
        {title: "Category", class: "border-b-2 border-primary hover:bg-primary cursor-pointer hover:text-white hover:font-bold p-2 mx-2", action: () => this.$router.push({path: "/category"})},
        {title: "Language", class: "border-b-2 border-primary hover:bg-primary cursor-pointer hover:text-white hover:font-bold p-2 mx-2", action: () => this.$router.push({path: "/language"})},
        {title: "Page", class: "border-b-2 border-primary hover:bg-primary cursor-pointer hover:text-white hover:font-bold p-2 mx-2", action: () => this.$router.push({path: "/page"})},
        {title: "Media", class: "border-b-2 border-primary hover:bg-primary cursor-pointer hover:text-white hover:font-bold p-2 mx-2", action: () => this.$router.push({path: "/media"})},
        {title: "Content", class: "border-b-2 border-primary hover:bg-primary cursor-pointer hover:text-white hover:font-bold p-2 mx-2", action: () => this.$router.push({path: "/content"})},
        {title: "General Articles", class: "border-b-2 border-primary hover:bg-primary cursor-pointer hover:text-white hover:font-bold p-2 mx-2", action: () => this.$router.push({path: "/general_article"})},
        {title: "Dictionary", class: "border-b-2 border-primary hover:bg-primary cursor-pointer hover:text-white hover:font-bold p-2 mx-2", action: () => this.$router.push({path: "/dictionary"})},
        {title: "User Management", class: "border-b-2 border-primary hover:bg-primary cursor-pointer hover:text-white hover:font-bold p-2 mx-2", action: () => this.$router.push({path: "/users"})},
        {title: "Logout", class: "hover:bg-primary cursor-pointer hover:text-white hover:font-bold p-2 mx-2", action: this.logout},
      ]
    }
  },
  computed: {
    toggleClass(){
      return  this.isMenu ? "flex-wrap md:w-1/4 bg-white shadow-md border-2 border-bg-primary" : "hidden"
    }
  },
  methods: {
    logout(){
      this.$store.commit("setToken", null);
      this.$store.commit("setProfile", null);
      this.$store.commit("toggleLogin");
      this.$router.push({name: "login"});
    }

  }
}
</script>
