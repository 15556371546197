<template>
    <component :is="make(source, lang)"></component>
</template>

<script>
import * as vue from 'vue';
import { Template } from '../library/template'
export default {
    name: "VueMarkdown",
    props:["source", "lang"],
    computed: {
        make(){
            return(v, pos) => {
                console.log(pos)
                const page = new Template().render(v, pos == "en" ? "right" : "left")
                return vue.compile(page);
            }
        }
    }

}
</script>
