<template>
    <button class="w-full drop-shadow-md bg-primary text-white p-2 font-semibold rounded" :click="onClick">{{text}}</button>
</template>

<script>
export default {
    name: "ContentButton",
    props: {
        text: {
           type: String,
           required: true 
        }
    },
    methods: {
        onClick(){
            this.$emit("click");
        }
    }
}
</script>

<style>

</style>