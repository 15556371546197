<template>
    <div>
        <ul>
            <li v-for="(item, key) in items" :key="key" :class="item.class" @click="item.action">{{item.title}}</li>
        </ul>
    </div>
</template>
<script>
export default {
    name: "MenuList",
    props: {
        items: {
            type: Array,
            default: () => []
        }
    },
}
</script>